export default function AssuredReturnsBlog() {
    
    const handleLocationSuccess = () => {
        // Push the event to the dataLayer
        window.dataLayer.push({
            event: 'mapsOpenSuccess',
            officeLocation: 'success'
        })}
        
    return ( 
    <>
        <section className="container mx-auto lg:max-w-4xl md:max-w-2xl pb-[40px] m-blogs">
            <div>
            <nav className="w-full font-montserrat mt-[32px]">
                <ol className="list-reset flex max-md:text-[14px]">
                    <li>
                    <a href="/" className="text-primary transition duration-150 ease-in-out hover:text-[#13293d] focus:text-[#13293d] active:text-[#60a5fa]">Home</a>
                    </li>
                    <li>
                    <span className="mx-2 text-black">/</span>
                    </li>
                    <li>
                    <a href="/#blog" className="text-primary transition duration-150 ease-in-out hover:text-[#13293d] focus:text-[#13293d] active:text-[#60a5fa]">Blogs</a>
                    </li>
                    <li>
                    <span className="mx-2 text-black">/</span>
                    </li>
                    <li className="text-black">Assured Returns</li>
                </ol>
            </nav>
            </div>

            <section className="max-md:mx-[10px] max-sm:mx-[0px]">
                <div className="flex flex-col">
                    <div className="md:text-[36px] text-[24px] font-spartan pt-[20px] leading-tight text-[#13293d]">5 Reasons to Consider an Assured Return Scheme for Real Estate Investment</div>
                    <hr className="my-[5px]"/>
                    
                    {/* <div className="flex gap-2 font-montserrat max-md:text-[14px]">
                        <span><span style={{fontWeight:"700"}}>Author -</span> Apoorva Raj |</span>
                        <span>5 Min Read</span>
                    </div> */}
                </div>

                <div className="overflow-hidden object-cover md:h-[400px] h-[200px] object-center my-[10px] lg:rounded-lg">
                    <img src="https://ik.imagekit.io/AcmeSpaces/Blog%20cover/blog_assured_returns_herov2.png" alt="Assured Returns Img" className="h-full w-full overflow-hidden object-cover"/>
                </div>
                <br/>

                <div>
                    <section className="flex-col flex font-montserrat text-left gap-[2px]">
                    {/* <div className="md:text-[24px] text-[20px] font-semibold">Introduction:</div> */}
                        {/* <ul className="md:text-[22px] text-[16px]">
                            <li className="list-outside list-disc">Are you looking to invest in real estate that promises both security and potential for high returns?</li>
                        </ul> */}
                        <div className="md:text-[18px] text-[16px]">
                        Are you considering investing in real estate? 
                        <br/>
                        An <strong className="text-[#13293d]">Assured Return Scheme </strong>might just be the solution you've been looking for.
                        <br/><br/>
                        Here are 5 reasons why this investment avenue could be a game-changer for you:
                        </div>
                        <br/>
                        <ul className="md:text-[18px] text-[16px]">
                                <div className="list-decimal">
                                    <li className="font-bold text-[#13293d]">Low Starting Investment:</li>
                                    <p className="">With an Assured Return Scheme, you can enter the real estate market with a relatively modest investment starting from <strong className="text-[#13293d]">50 lakhs</strong>. So, if you are looking to invest in real estate for the first time, investing in an assured return scheme might just be the right thing for you!</p>
                                </div>
                                
                                <br/>

                                <div className="list-decimal">
                                    <li className="font-bold text-[#13293d]">Immediate Fixed Income:</li>
                                    <p className="">One of the most attractive features of an Assured Return Scheme is the promise of a <strong className="text-[#13293d]">monthly fixed income</strong> from day one.
                                    </p>
                                </div>

                                <br/>

                                <div className="overflow-hidden object-cover md:h-[400px] h-[200px] object-center lg:rounded-lg">
                                    <img src="https://ik.imagekit.io/AcmeSpaces/assuredreturns5.png" alt="Assured Returns Img" className="h-full w-full overflow-hidden object-cover"/>
                                </div>
                                <br/>
                                

                                <div className="list-decimal">
                                    <li className="font-bold text-[#13293d]">High Return on Investment:</li>
                                    <p className="">Typically, Assured Return Schemes offer an impressive ROI, often averaging around <strong className="text-[#13293d]">12% per annum</strong> until possession of the property. In addition, some properties even come with a lease guarantee after possession, further bolstering the potential for long-term profitability and minimizing risk for investors.</p>
                                </div>
                                <br/>
                                <div className="list-decimal">
                                    <li className="font-bold text-[#13293d]">Flexible Payment Plans:</li>
                                    <p className=""> Assured Return Schemes come with <strong className="text-[#13293d]">flexible payment plans,</strong> allowing investors to pay <strong className="text-[#13293d]">50% of the investment upfront</strong> and the remaining amount in installments.</p>                                
                                </div>

                                <br/>

                                <div className="list-decimal">
                                    <li className="font-bold text-[#13293d]">Diverse Investment Options:</li>
                                    <p className=""> Whether you're interested in <strong className="text-[#13293d]">office spaces, shops in bustling commercial areas, or even entertainment and food court spaces,</strong> Assured Return Schemes offer a variety of investment options to suit your preferences and investment goals.</p>                                
                                </div>

                                <br/>
                                <div >
                                    In conclusion, an Assured Return Scheme presents an attractive opportunity for investors looking to enter the real estate market or diversify their investment portfolios.
                                    <br/>
                                    With its accessible entry threshold, immediate fixed income, high ROI, flexible payment plans, and diverse investment options, it's no wonder that more investors are turning to Assured Return Schemes as a smart investment choice.
                                </div>
                        </ul>

                        <br/>
                        {/* <div className="md:text-[22px] text-[20px] font-semibold">Conclusion:</div> */}
                        <div className="md:text-[18px] text-[16px]">
                        <a target="_blank" rel='noreferrer' href="https://wa.link/4c0wcw" className="underline text-[#13293d] font-bold">Get in touch with us today</a> in case you need:
                         <ul className="list-image-[url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTQiIGhlaWdodD0iMTIiIHZpZXdCb3g9IjAgMCAxNCAxMiIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiBmaWxsPSIjMzhiZGY4Ij48cGF0aCBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0xMy42ODUuMTUzYS43NTIuNzUyIDAgMCAxIC4xNDMgMS4wNTJsLTggMTAuNWEuNzUuNzUgMCAwIDEtMS4xMjcuMDc1bC00LjUtNC41YS43NS43NSAwIDAgMSAxLjA2LTEuMDZsMy44OTQgMy44OTMgNy40OC05LjgxN2EuNzUuNzUgMCAwIDEgMS4wNS0uMTQzWiIgLz48L3N2Zz4=')]">
                            <li>Advice on Assured Return Investments.</li>
                            <li>Invest in assured return real estate properties</li>
                         </ul>
                        </div>
                        
                        <br/>
                        <div className="md:text-[22px] text-[20px] font-semibold">Contact Info:</div>
                        <div className="md:text-[18px] text-[16px] flex flex-col">
                        <span className="text-left">Phone: <a  target="_blank" rel='noreferrer' href="tel:+919910109700" className="underline">9910109700</a></span>
                        <span className="text-left">Location: <a  target="_blank" rel='noreferrer' onClick={handleLocationSuccess} id="WS_Office_assuredReturn_blog" href="https://maps.app.goo.gl/aijdNdgpSzWqxm4o6" className="underline">SCO-61, OMAXE WORLD STREET, Sector 79, Faridabad, Haryana 121004</a></span>
                        </div>

                    </section>
                </div>
            </section>

        </section>
    </> 
    )};