export default function HSVPstiltplus4rule() {
    
    const handleLocationSuccess = () => {
        // Push the event to the dataLayer
        window.dataLayer.push({
            event: 'mapsOpenSuccess',
            officeLocation: 'success'
        })}

    return ( 
    <>
        <section className="container mx-auto lg:max-w-4xl md:max-w-2xl pb-[40px] m-blogs">
            <div>
            <nav className="w-full font-montserrat mt-[32px]">
                <ol className="list-reset flex max-md:text-[14px]">
                    <li>
                    <a href="/" className="text-primary transition duration-150 ease-in-out hover:text-[#13293d] focus:text-[#13293d] active:text-[#60a5fa]">Home</a>
                    </li>
                    <li>
                    <span className="mx-2 text-black">/</span>
                    </li>
                    <li>
                    <a href="/#blog" className="text-primary transition duration-150 ease-in-out hover:text-[#13293d] focus:text-[#13293d] active:text-[#60a5fa]">Blogs</a>
                    </li>
                    <li>
                    <span className="mx-2 text-black">/</span>
                    </li>
                    <li className="text-black">HSVP Stilt+4 Floor Policy</li>
                </ol>
            </nav>
            </div>

            <section className="max-md:mx-[10px] max-sm:mx-[0px]">
                <div className="flex flex-col">
                    <div className="md:text-[44px] text-[24px] font-spartan pt-[20px] leading-tight text-[#13293d]" style={{fontWeight:"600"}}>New HUDA Stilt+4 Floor Policy: Everything You Need to Know</div>
                    <hr className="my-[5px]"/>
                    
                    {/* <div className="flex gap-2 font-montserrat max-md:text-[14px]">
                        <span><span style={{fontWeight:"700"}}>Author -</span> Apoorva Raj |</span>
                        <span>5 Min Read</span>
                    </div> */}
                </div>

                <div className="overflow-hidden object-cover md:h-[400px] h-[200px] object-center my-[10px] lg:rounded-lg">
                    <img src="https://ik.imagekit.io/AcmeSpaces/Blog%20cover/blog_cover_s+4_home_3v2.png" alt="hsvp img" className="h-full w-full overflow-hidden object-cover object-left"/>
                </div>
                <br/>

                <div>
                    <section className="flex-col flex font-montserrat text-left gap-[2px]">
                    {/* <div className="md:text-[24px] text-[20px] font-semibold">Introduction:</div> */}
                        {/* <ul className="md:text-[22px] text-[16px]">
                            <li className="list-outside list-disc">Are you looking to invest in real estate that promises both security and potential for high returns?</li>
                        </ul> */}
                        <div className="md:text-[18px] text-[16px]">
                        <strong className="text-[#13293d]">HSVP (Haryana Shehri Vikas Pradhikaran)</strong>has introduced a new policy update for approving building plans under Stilt+4 scheme. This blog explains the changes in this policy due to which <strong className="text-[#13293d]">Stilt+4</strong> floors will be approved in plots but with certain <i>conditions.</i>  
                        <br/>
                        </div>
                        <br/>
                        <ul className="md:text-[18px] text-[16px]">
                                <div className="list-decimal">
                                    <li className="font-bold text-[#13293d]">Policy for Plots Bigger Than 180 Square Yards</li>
                                    <table class="table-auto w-full md:whitespace-nowrap">
                                        <thead>
                                            <tr>
                                                <th className="w-1/2 lg:py-4 py-2 lg:px-2 px-2 border border-gray-500 bg-[lightgray] max-sm:text-[12px] text-[#13293d]">Road Width</th>
                                                <th className="lg:py-4 py-2 lg:px-2 px-2 border border-gray-500 bg-[lightgray] max-sm:text-[12px] text-[#13293d]">Neighbour's Consent?</th>
                                                <th className="lg:py-4 py-2 lg:px-2 px-2 border border-gray-500 bg-[lightgray] max-sm:text-[12px] text-[#13293d]">Approved?</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td className="lg:py-4 py-2 lg:px-2 px-2 border border-gray-500 max-sm:text-[12px]">Less than 10M</td>
                                                <td className="lg:py-4 py-2 lg:px-2 px-2 border border-gray-500 max-sm:text-[12px] text-center">-</td>
                                                <td className="lg:py-4 py-2 lg:px-2 px-2 border border-gray-500 max-sm:text-[12px] text-center">NO</td>
                                            </tr>
                                            <tr>
                                                <td className="lg:py-4 py-2 lg:px-2 px-2 border border-gray-500 max-sm:text-[12px]" rowspan="2">Greater than 10M</td>
                                                <td className="lg:py-4 py-2 lg:px-2 px-2 border border-gray-500 max-sm:text-[12px] text-center">YES</td>
                                                <td className="lg:py-4 py-2 lg:px-2 px-2 border border-gray-500 max-sm:text-[12px] text-center">YES</td>
                                            </tr>
                                            <tr>
                                                <td className="lg:py-4 py-2 lg:px-2 px-2 border border-gray-500 max-sm:text-[12px] text-center">NO</td>
                                                <td className="lg:px-2 px-2 border border-gray-500 max-sm:text-[12px] text-center">YES<br/><span className="text-[12px]">(1.8m to be left on the said side*)</span></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <p className="text-[#13293d] pt-[4px]"><i>*Side can be left/right/rear</i></p>
                                </div>
                                
                                <br/>

                                <div className="list-decimal">
                                    <li className="font-bold text-[#13293d]">Rules for Deen Dayal Colonies</li>
                                    <table class="table-auto w-full md:whitespace-nowrap">
                                        <thead>
                                            <tr>
                                                <th className="w-1/2 lg:py-4 py-2 lg:px-2 px-2 border border-gray-500 bg-[lightgray] max-sm:text-[12px] text-[#13293d]">Layout Plan</th>
                                                <th className="lg:py-4 py-2 lg:px-2 px-2 border border-gray-500 bg-[lightgray] max-sm:text-[12px] text-[#13293d]">Road Width</th>
                                                <th className="lg:py-4 py-2 lg:px-2 px-2 border border-gray-500 bg-[lightgray] max-sm:text-[12px] text-[#13293d]">Approved?</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td className="lg:py-4 py-2 lg:px-2 px-2 border border-gray-500 max-sm:text-[12px]">18 persons per plot</td>
                                                <td className="lg:py-4 py-2 lg:px-2 px-2 border border-gray-500 max-sm:text-[12px] text-center">Any</td>
                                                <td className="lg:py-4 py-2 lg:px-2 px-2 border border-gray-500 max-sm:text-[12px] text-center">YES</td>
                                            </tr>
                                            <tr>
                                                <td className="lg:py-4 py-2 lg:px-2 px-2 border border-gray-500 max-sm:text-[12px]">13.5 persons per plot</td>
                                                <td className="lg:py-4 py-2 lg:px-2 px-2 border border-gray-500 max-sm:text-[12px] text-center">Less than 10M</td>
                                                <td className="lg:py-4 py-2 lg:px-2 px-2 border border-gray-500 max-sm:text-[12px] text-center">NO</td>
                                            </tr>
                                            <tr>
                                                <td className="lg:py-4 py-2 lg:px-2 px-2 border border-gray-500 max-sm:text-[12px]">13.5 persons per plot</td>
                                                <td className="lg:py-4 py-2 lg:px-2 px-2 border border-gray-500 max-sm:text-[12px] text-center">Greater than 10M</td>
                                                <td className="lg:px-2 px-2 border border-gray-500 max-sm:text-[12px] text-center">YES</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <p className="text-[#13293d] pt-[4px]"><i>*Layout plan as mentioned in the RERA license of the project</i></p>
                                </div>

                                <br/>

                                <div className="list-decimal">
                                    <li className="font-bold text-[#13293d]">What does this mean for Faridabad?</li>
                                    <ul className="">
                                        <li className="list-disc pt-[8px]">For <strong className="text-[#13293d]">sectors </strong>like <strong className="text-[#13293d]">Sec-14, 15, 16, 17, 15A, 16A </strong>where <strong className="text-[#13293d]">sector roads </strong>are usually <strong className="text-[#13293d]">9M, </strong>new building plans for S+4 floors are <strong className="text-[#13293d]">unlikely </strong>to be approved in the future.</li>
                                        <li className="list-disc pt-[4px]">As for <strong className="text-[#13293d]">Greater Faridabad, </strong>most sector roads are <strong className="text-[#13293d]">at least 12M </strong>wide, and new building plans for S+4 floors would be <strong className="text-[#13293d]">approved </strong>without much hassle and with the neighbour’s consent.</li>
                                        <li className="list-disc pt-[4px]">Most <strong className="text-[#13293d]">Deen Dayal Colonies </strong>in Greater Faridabad have a layout plan approval for <strong className="text-[#13293d]">13.5 persons per plot and have 9M roads. </strong> So, no S+4 floors in most <strong className="text-[#13293d]">Deen Dayal Colonies </strong>but we are rechecking with builders to look for any exceptions.</li>
                                    </ul>
                                </div>
                                
                                <br/>
                                <div className="list-decimal">
                                    <li className="font-bold text-[#13293d]">What does this mean for you?</li>
                                    <p className="pt-[8px]">If you are an investor or a builder, now is your time!</p>
                                    <ul>
                                        <li className="list-disc pt-[8px]">As for investors, just the S+4 news has caused the prices to jump up by over <strong className="text-[#13293d]">10% in a week. </strong>Buy a plot now as these rates are expected further!
                                        </li>
                                        <li className="list-disc pt-[8px]">
                                        Builders can start with their turnkey S+4 projects which were long halted. People are waiting for more luxurious builder floors with spacious rooms and new interiors
                                        </li>
                                    </ul>                                
                                </div>
                        </ul>

                        <br/>
                        {/* <div className="md:text-[22px] text-[20px] font-semibold">Conclusion:</div> */}
                        <div className="md:text-[18px] text-[16px]">
                        <a target="_blank" rel='noreferrer' href="https://wa.link/6n3hp9" className="underline text-[#13293d] font-bold">Get in touch with us today</a> in case you need:
                         <ul className="list-image-[url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTQiIGhlaWdodD0iMTIiIHZpZXdCb3g9IjAgMCAxNCAxMiIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiBmaWxsPSIjMzhiZGY4Ij48cGF0aCBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0xMy42ODUuMTUzYS43NTIuNzUyIDAgMCAxIC4xNDMgMS4wNTJsLTggMTAuNWEuNzUuNzUgMCAwIDEtMS4xMjcuMDc1bC00LjUtNC41YS43NS43NSAwIDAgMSAxLjA2LTEuMDZsMy44OTQgMy44OTMgNy40OC05LjgxN2EuNzUuNzUgMCAwIDEgMS4wNS0uMTQzWiIgLz48L3N2Zz4=')]">
                            <li>Buy or Sell Plots.</li>
                            <li>Advice on Real Estate investment.</li>
                            <li>To Apply for a bank loan for your property.</li>
                         </ul>
                        </div>
                        
                        <br/>
                        <div className="md:text-[22px] text-[20px] font-semibold">Contact Info:</div>
                        <div className="md:text-[18px] text-[16px] flex flex-col">
                        <span className="text-left">Phone: <a target="_blank" rel='noreferrer' href="tel:+919910109700" className="underline">9910109700</a></span>
                        <span className="text-left">Location: <a target="_blank" rel='noreferrer' onClick={handleLocationSuccess} id="WS_Office_HSVP_blog" href="https://maps.app.goo.gl/aijdNdgpSzWqxm4o6" className="underline">SCO-61, OMAXE WORLD STREET, Sector 79, Faridabad, Haryana 121004</a></span>
                        </div>

                    </section>
                </div>
            </section>

        </section>
    </> 
    )};